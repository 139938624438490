body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'OpenSans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #270048;
}
.grecaptcha-badge { 
  visibility: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  scroll-margin-top: 30px;
}
.active {
  outline: 2px solid #03f87e;
  outline-offset: -2px;
}
.loaderPlan{
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  background-color: #270048;
  display: flex;
  justify-content: center;
  align-items: center;
  }


